import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { FiHeart } from "react-icons/fi";

import { postEntityReaction } from "@/api/api";

type Props = {
  entityId: string;
  entityType: string;
  likeCount: number;
  bgColor?: string;
  strokeColor?: string;
  setLikesCount?:
    | ((_value: ((_prevState: number) => number) | number) => void)
    | undefined;
};

export const LikeButton = ({
  entityId,
  entityType,
  likeCount,
  bgColor = "transparent",
  strokeColor = "#000",
  setLikesCount,
}: Props) => {
  const cookieName = "LIKES";
  const [counter, setCounter] = useState(likeCount);
  const [cookies, setCookie] = useCookies([cookieName]);
  const [liked, setLiked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCounter(likeCount);
    setLiked(checkIfLiked());
    // Reload the state when the entityId changes (e.g. when the user navigates to a different page)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId]);

  useEffect(() => {
    setLiked(checkIfLiked());
  }, [cookies]);

  const checkIfLiked = () => {
    let likedArr = cookies[cookieName];

    if (likedArr == null) {
      return false;
    }

    return (
      likedArr.filter(
        (item: { entityId: string; entityType: string }) =>
          item.entityType === entityType && item.entityId === entityId,
      ).length > 0
    );
  };

  const handleCookie = (isLiked: boolean) => {
    let oldVal = cookies[cookieName];
    let arr = [];
    if (oldVal != null) {
      arr = oldVal;
    }

    let obj = {
      entityId,
      entityType,
    };
    if (isLiked) {
      arr.push(obj);
    } else {
      arr = arr.filter(
        (item: { entityId: string; entityType: string }) =>
          !(item.entityType === entityType && item.entityId === entityId),
      );
    }

    const d = new Date();
    const expiryDate = new Date(d.setDate(d.getDate() + 100));

    setCookie(cookieName, JSON.stringify(arr), {
      path: "/",
      expires: expiryDate,
    });
  };

  const handleClick = async () => {
    const cookiesEnabled = navigator.cookieEnabled;

    // If cookies are disabled, show an alert
    if (!cookiesEnabled) {
      alert(
        "Cookies are disabled in your browser. Please enable cookies to use this feature.",
      );
      return;
    }

    setIsLoading(true);

    let isLiked = !liked;

    try {
      const newLikeCount = await postEntityReaction(
        entityId,
        entityType,
        isLiked,
      );
      setLikesCount ? setLikesCount(newLikeCount) : setCounter(newLikeCount);
      handleCookie(isLiked);
      setLiked(isLiked);
      setIsLoading(false);
    } catch (error) {
      alert(
        "Er ging iets fout bij het verwerken van jouw like. Probeer het later opnieuw.",
      );
    }
  };

  if (entityType && entityId) {
    return (
      <button disabled={isLoading} className={"flex"} onClick={handleClick}>
        <FiHeart
          className={liked ? "liked" : ""}
          size={24}
          fill={liked ? "red" : bgColor}
          stroke={liked ? "red" : strokeColor}
        />
        <p
          className={`ml-[5px] font-avenir text-[16px] font-light md:text-[18px] text-[${strokeColor}]`}
        >
          {setLikesCount ? likeCount : counter}
        </p>
      </button>
    );
  } else {
    return <span></span>;
  }
};
